import React, { Suspense } from 'react'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import {
  createRootRouteWithContext,
  Outlet,
  ScrollRestoration,
  useRouter,
} from '@tanstack/react-router'
import { RouterProvider, useLocale } from 'react-aria-components'
import { z } from 'zod'

import type { QueryClient } from '@tanstack/react-query'

import { Toaster } from '@fysioscout/ui/status/toast'
import { Text } from '@fysioscout/ui/typography/text'
import { useTheme } from '@fysioscout/utils/react'

import { env } from '@/config/env'

export interface AppRouterContext {
  /** Query client used for the application. */
  queryClient: QueryClient
}

const searchSchema = z.object({
  redirect_to: z.string().url().optional().catch(''),
})

export const Route = createRootRouteWithContext<AppRouterContext>()({
  component: RootLayout,
  validateSearch: (search) => searchSchema.parse(search),
})

function RootLayout() {
  const router = useRouter()
  const { locale, direction } = useLocale()
  const { theme } = useTheme()

  React.useEffect(() => {
    document.documentElement.lang = locale
    document.documentElement.dir = direction
  }, [direction, locale])

  return (
    <div data-testid={'root-view'} lang={locale} dir={direction}>
      <MockingIndicator />
      <ScrollRestoration />
      <Toaster
        theme={theme}
        toastOptions={{ classNames: { toast: 'gap-3' } }}
        position={'bottom-center'}
      />

      <RouterProvider
        navigate={(to, options) => router.navigate({ to, ...options })}
        useHref={(to) => router.buildLocation({ to }).href}
      >
        <Outlet />
      </RouterProvider>

      <Suspense fallback={null}>
        <TanStackRouterDevtools />
      </Suspense>

      <ReactQueryDevtools initialIsOpen={false} />
    </div>
  )
}

const TanStackRouterDevtools = import.meta.env.PROD
  ? () => null
  : React.lazy(() =>
      import('@tanstack/router-devtools').then((res) => ({
        default: res.TanStackRouterDevtools,
      })),
    )

function MockingIndicator() {
  if (!env.VITE_API_MOCKING) return null

  return (
    <div className={'stack center bg-yellow-5 fixed top-0 h-6 w-full px-2'}>
      <Text size={'0'} className={'leading-none'} medium>
        Mocking active
      </Text>
    </div>
  )
}
