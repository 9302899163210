import { createFileRoute, Link as RouterLink, useNavigate } from '@tanstack/react-router'
import { useTitle } from 'ahooks'
import { TooltipTrigger } from 'react-aria-components'

import type { LoginBody } from '@/features/auth/api/use-login'

import { Button } from '@fysioscout/ui/buttons/button'
import { Link as UILink } from '@fysioscout/ui/navigation/link'
import { Tooltip } from '@fysioscout/ui/overlays/tooltip'
import { Heading } from '@fysioscout/ui/typography/heading'
import { Text } from '@fysioscout/ui/typography/text'

import { useLogin } from '@/features/auth/api/use-login'
import { LoginForm } from '@/features/auth/components/login-form'

export const Route = createFileRoute('/_unauth/login')({
  component: LoginView,
})

function LoginView() {
  useTitle('Log ind')
  const loginMutation = useLogin()
  const navigate = useNavigate()

  const handleSubmit = (body: LoginBody) => {
    loginMutation.mutate(body, {
      onSuccess: () => {
        void navigate({
          to: '/otp',
          search: (search) => ({ ...search, email: body.email }),
        })
      },
    })
  }

  return (
    <div data-testid={'login-view'}>
      <Heading size={'5'}>Log ind</Heading>

      <LoginForm className={'mt-8'} submit={handleSubmit} isPending={loginMutation.isPending} />

      <div className={'flex flex-col'}>
        <div className={'my-6 flex items-center gap-2'}>
          <hr className={'border-neutral-6 w-full'} />

          <Text size={'2'} muted className={'shrink-0'}>
            Har du ikke en bruger?
          </Text>

          <hr className={'border-neutral-6 w-full'} />
        </div>

        <TooltipTrigger delay={150} closeDelay={150}>
          <Button variant={'outline'} color={'accent'} isVisuallyDisabled>
            Opret ny profil
          </Button>

          <Tooltip placement={'bottom'}>
            Denne funktion er midlertidigt kun tilgængelig i appen
          </Tooltip>
        </TooltipTrigger>

        <UILink size={'xs'} className={'mt-8'}>
          <RouterLink to={'/forgotten-password'}>Glemt adgangskode?</RouterLink>
        </UILink>
      </div>
    </div>
  )
}
